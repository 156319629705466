import * as React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { LocalizedLink } from "gatsby-theme-i18n"
import { GatsbyImage } from "gatsby-plugin-image"

const InsightsTemplate = ({ data }) => {
  const { frontmatter, body, excerpt } = data.mdx
  const {
    title,
    category,
    featuredImage,
    copyrightImage,
    author,
    author_image,
    job,
    date,
  } = frontmatter

  const posts = data.related_posts.nodes
  return (
    <Layout>
      <Seo title={title} description={excerpt} />
      <div className="shell">
        <div className="insights-post__wrapper">
          <p className="insights-post__info">
            <strong>{category}</strong> - {date}
          </p>
          <h1 className="insights-post__title">{title}</h1>
          <div className="insights-post__featured-image">
            <GatsbyImage
              image={featuredImage.childImageSharp.gatsbyImageData}
              alt={title}
            />
          </div>
          <p className="insights-post__featured-image-copyright">
            {copyrightImage}
          </p>
          <div className="insights-post__text mdx__body ">
            {data.mdx ? (
              <MDXRenderer>{body}</MDXRenderer>
            ) : (
              <div>This page hasn't been translated yet</div>
            )}
          </div>
          <div className="insights-post__divider" />
          <div className="insights-post__author">
            <GatsbyImage
              image={author_image.childImageSharp.gatsbyImageData}
              alt={title}
              className="insights-post__author-image"
            />
            <div>
              <h5 className="insights-post__author-name">{author}</h5>
              <p className="insights-post__author-job">{job}</p>
            </div>
          </div>
        </div>
        {posts.length > 0 && (
          <div className="insights-post__recommended-posts">
            <h3 className="insights-post__recommended-posts_title">
              Articles sur le même sujet
            </h3>
            <div className="insights-post__recommended-posts_grid">
              {posts.map((node, index) => {
                const { slug, title, featuredImage, category } =
                  node.childMdx.frontmatter
                const { excerpt } = node.childMdx
                return (
                  <LocalizedLink
                    to={slug}
                    key={`insights_post_${index}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="insights-post__recommended-posts_post">
                      <GatsbyImage
                        image={featuredImage.childImageSharp.gatsbyImageData}
                        alt={title}
                        className="insights-post__recommended-posts_post-image"
                      />
                      <div className="insights-post__recommended-posts_post-text">
                        <div>
                          <p className="insights-post__recommended-posts_post-info">
                            <strong>{category}</strong> - {date}
                          </p>
                          <h4 className="insights-post__recommended-posts_post-title">
                            {title}
                          </h4>
                          <p className="insights-post__recommended-posts_post-excerpt">
                            {excerpt}
                          </p>
                        </div>
                        <div className="insights-post__recommended-posts_post-author">
                          <GatsbyImage
                            image={author_image.childImageSharp.gatsbyImageData}
                            alt={author}
                            className="insights-post__recommended-posts_post-author-image"
                          />
                          <div>
                            <h5 className="insights-post__recommended-posts_post-author-name">
                              {author}
                            </h5>
                            <p className="insights-post__recommended-posts_post-author-job">
                              {job}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </LocalizedLink>
                )
              })}
            </div>
            <div className="insights-post__recommended-posts_background" />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default InsightsTemplate

export const query = graphql`
  query ($locale: String!, $slug: String!, $category: String!) {
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      frontmatter {
        slug
        title
        category
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        copyrightImage
        author
        author_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        job
        date(formatString: "D MMMM YYYY", locale: $locale)
      }
      body
      excerpt
    }
    related_posts: allFile(
      filter: {
        sourceInstanceName: { eq: "insights" }
        childMdx: {
          fields: { locale: { eq: $locale } }
          frontmatter: { slug: { ne: $slug }, category: { eq: $category } }
        }
      }
      limit: 3
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            slug
            featuredImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            category
            date(formatString: "D MMMM YYYY", locale: $locale)
          }
          excerpt
        }
      }
    }
  }
`
